import React from "react"
import './App.css';
import { Route, Switch } from "react-router-dom";
import Performance from "./Performance Evaluation System/Performance"

function App() {
  return (
    <>
      <Switch>
        <Route exact path="/">
          <Performance/>
        </Route>
      </Switch>
    </>
  );
}

export default App;
