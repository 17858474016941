

const Sliders = () => {
    return (
        <div>
       
        </div>
    )
}

export default Sliders
